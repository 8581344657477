import { Grid } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import FlightDataIcon from "../../icons/param-mapping/FlightDataIcon";
import useAxios from "../../api/useAxios";
import ParamImportDetails from "./ParamImportDetails";

function FlightDataDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        isAdmin,
        textOutShow,
        handleShow,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <FlightDataIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Redaction" : "Create New Redaction"}
            titleIcon={iconType}
        />
    );
    const autoCRedactedParams = useRef();

    const [redactionTypes, setRedactionTypes] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [dataFrames, setDataFrames] = useState([]);

    const [paramsToList, setParamsToList] = useState([]);
    const [openParamsImport, setOpenParamsImport] = useState(false);

    const clearRedactedParamSelection = () => {
        const autoCRefs = [autoCRedactedParams];

        autoCRefs.forEach((item) => {
            const ele = item.current;

            if (ele) {
                const clearBtn = ele.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                )[0];
                if (clearBtn) {
                    clearBtn.click();
                }
            }
        });
    };
    useEffect(() => {
        createApiEndPoint(ENDPOINTS.REDACTIONTYPE)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const redactionTypesData = res.data;
                let redactionTypeOptions = redactionTypesData
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setRedactionTypes(redactionTypeOptions);
            })
            .catch((err) => console.log(err));

        if (isAdmin) {
            createApiEndPoint(ENDPOINTS.AIRLINE)
                .fetchAll("getKeyValuePairs")
                .then((res) => {
                    const airlinesToDisplay = res.data;
                    let airlineOptions = airlinesToDisplay
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setAirlines(airlineOptions);
                })
                .catch((err) => console.log(err));
        }
    }, []);
    useEffect(() => {
        createApiEndPoint(ENDPOINTS.DATAFRAME)
            .fetchAll(
                `getSelectListDtoByAirlineAndDataFrameType?dataIn=${values.airlineId}&dataIn=1&dataIn=2&dataIn=3&dataIn=6`
            )
            .then((res) => {
                const dataFramesData = res.data;
                let dataFramesOptions = dataFramesData
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setDataFrames(dataFramesOptions);
            })
            .catch((err) => console.log(err));

        setParamsToList([]);
    }, [values.airlineId]);

    useEffect(() => {
        if (values.dataFrameId > 0) {
            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(`getMnemonics?dataFrameId=${values.dataFrameId}`)
                .then((res) => {
                    const paramsToDisplay = res.data;
                    if (paramsToDisplay) {
                        let paramsList = paramsToDisplay
                            .sort((a, b) => (a.mnemonic > b.mnemonic ? 1 : -1))
                            .map((item) => ({
                                id: item.mnemonic,
                                label: item.mnemonic,
                                value: item.mnemonic,
                                description: item.description,
                            }));
                        setParamsToList(paramsList);
                    } else {
                        setParamsToList([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setParamsToList([]);
        }
    }, [values.dataFrameId]);

    /*  useEffect(() => {
        // if(values.id == 0)
      //  clearRedactedParamSelection();
    }, [values]);*/

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    const handleOnImport = () => {
        setOpenParamsImport(true);
        setOpen(false);
    };

    const getLookupData = () => {
        return paramsToList.map((item) => item.label);
    };

    return (
        <div>
            <ParamImportDetails
                airlineId={values.airlineId}
                open={openParamsImport}
                setOpen={setOpenParamsImport}
                lookupData={getLookupData}
                setParentOpen={setOpen}
                onAddParams={(paramsToImport) => {
                
                   let paramToListValues = paramsToList.map(x=>x.value);
                   let paramsToImportValues = paramsToImport;
                  
                   let intersection = paramToListValues.filter(x => paramsToImportValues.includes(x));
                    
                    let selectedParams = intersection.map((item) => ({
                        id: item,
                        label: item,
                        value: item,
                        description: item,
                    }));
                 

                    setValues({
                        ...values,
                        redactedParams: [
                          //  ...values.redactedParams,
                            ...selectedParams,
                        ],
                    });
                    setOpenParamsImport(false);
                    setOpen(true);
                }}
            />

            <GddDialog
                title={dlgTitle}
                open={open}
                // maxWidth="lg"
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            variant="outlined"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="description"
                            label="Description"
                            required={false}
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="redactionTypeId"
                            label="Redaction Type"
                            required={true}
                            options={redactionTypes}
                            value={values.redactionTypeId}
                            onChange={(e) => {
                                handleShow(e.target.value);
                                handleInputChange(e);
                            }}
                            error={errors.redactionTypeId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="textualOutput"
                            label="Textual Output"
                            value={values.textualOutput}
                            disabled={!textOutShow}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    {isAdmin && (
                        <Grid item xs={12}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="airlineId"
                                label="Airline"
                                required={true}
                                options={airlines}
                                value={values.airlineId}
                                onChange={(e) => {
                                    clearRedactedParamSelection();
                                    setTimeout(() => {
                                        handleInputChange(e);
                                    }, 100);
                                }}
                                error={errors.airlineId}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="dataFrameId"
                            label="Data Frame"
                            required={true}
                            options={dataFrames}
                            value={values.dataFrameId}
                            onChange={(e) => {
                                clearRedactedParamSelection();
                                handleInputChange(e);
                            }}
                            error={errors.dataFrameId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddMultiSelect
                            autoCRef={autoCRedactedParams}
                            items={paramsToList}
                            required
                            // getOptionDisabled={getOptionDisabled}
                            label="Select Parameters"
                            placeholder="Parameters"
                            selectAllLabel="Select all"
                            onChange={(vals) => {
                                let evt = {
                                    target: {
                                        name: "redactedParams",
                                        value: [...vals],
                                    },
                                };
                                handleInputChange(evt);
                            }}
                            values={values.redactedParams}
                            error={errors.redactedParams}
                            noOptionsText="No parameters to list"
                            includeImportOption={true}
                            onImport={handleOnImport}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default FlightDataDetails;
