import React from "react";
import MaterialTable from "@material-table/core";

import { forwardRef } from "react";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ViewColumn from "@mui/icons-material/ViewColumn";
import ReplayIcon from "@mui/icons-material/Replay";
import RepeatIcon from "@mui/icons-material/Repeat";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import useAxios from "../../api/useAxios";
import { Paper, TextField } from "@mui/material";
import { ReplayOutlined, ReplyAllOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";

const tableIcons = {
    Add: forwardRef((props, ref) => (
        <AddBox {...props} fontSize="small" ref={ref} />
    )),
    Check: forwardRef((props, ref) => (
        <Check {...props} fontSize="small" ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
        <Clear {...props} fontSize="small" ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} fontSize="small" ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} fontSize="small" ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
        <EditOutlinedIcon {...props} fontSize="small" ref={ref} />
    )),
    Export: forwardRef((props, ref) => (
        <SaveAlt {...props} fontSize="small" ref={ref} />
    )),
    Filter: forwardRef((props, ref) => (
        <FilterList {...props} fontSize="small" ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => (
        <FirstPage {...props} fontSize="small" ref={ref} />
    )),
    LastPage: forwardRef((props, ref) => (
        <LastPage {...props} fontSize="small" ref={ref} />
    )),
    NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} fontSize="small" ref={ref} />
    )),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} fontSize="small" ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} fontSize="small" ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} fontSize="small" ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} fontSize="small" ref={ref} />
    )),
    EmailOutlinedIcon: forwardRef((props, ref) => (
        <EmailOutlinedIcon {...props} fontSize="small" ref={ref} />
    )),
};

function GddTable({
    title,
    cols,
    data,
    additionalFilters,
    onAdd,
    onEdit,
    onEditSelected,
    onDelete,
    onDeleteAll,
    onDeleteSelected,
    onRetry,
    onRetrySelected,
    onClone,
    getBaseUrlExt,
    selectableRows = false,
    showEditSelected = false,
    showDeleteAll = false,
    showDeleteSelected = false,
    showRetry = false,
    showRetrySelected = false,
    showClone = false,
    rowDataOverride = null,
    onRowDoubleClick = null,
    showAdd = true,
    showEdit = true,
    showDelete = true,
    showColumns = false,
    showGrouping = true,
    showFilters = true,
    showExport = true,
    tableRef = null,
    fetchEndPoint = null, // ENDPOINTS.<endpoint>
    baseUrlExt = null, // url string after the base url
    pagedSearchString = null,
    saveFilters = null,
    ...other
}) {
    let { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const getActions = () => {
        let actions = [];

        if (showAdd) {
            actions.push({
                icon: () => <AddBox sx={{ mt: "2px" }} />,
                tooltip: "Add",
                isFreeAction: true,
                onClick: (event) => {
                    onAdd(event);
                },
            });
        }

        if (showEdit) {
            actions.push({
                icon: () => <EditOutlinedIcon fontSize="inherit" />,
                tooltip: "Edit",
                position: "row",
                onClick: (event, rowData) => {
                    onEdit(rowData);
                },
            });
        }

        if (showDelete) {
            actions.push({
                icon: () => <DeleteOutline fontSize="inherit" />,
                tooltip: "Delete",
                position: "row",
                onClick: (event, rowData) => {
                    onDelete(rowData);
                },
            });
        }

        if (showEditSelected) {
            actions.push({
                icon: () => <EditOutlinedIcon fontSize="inherit" />,

                tooltip: "Edit Selected",
                position: "toolbarOnSelect",
                onClick: (event, data) => {
                    onEditSelected(data);
                },
            });
        }

        if (showDeleteAll) {
            actions.push({
                icon: () => <Clear fontSize="small" />,
                tooltip: "Delete All",
                isFreeAction: true,
                onClick: () => {
                    onDeleteAll();
                },
            });
        }

        if (showDeleteSelected) {
            actions.push({
                icon: () => <DeleteOutline fontSize="inherit" />,
                tooltip: "Delete Selected",
                position: "toolbarOnSelect",
                onClick: (event, data) => {
                    onDeleteSelected(data);
                },
            });
        }

        if (showRetry) {
            actions.push({
                icon: () => <ReplayIcon fontSize="inherit" />,

                tooltip: "Retry",
                //isFreeAction: false,
                position: "row",
                onClick: (event, data) => {
                    onRetry(data);
                },
            });
        }

        if (showRetrySelected) {
            actions.push({
                icon: () => <ReplayOutlined fontSize="inherit" />,

                tooltip: "Retry Selected",
                position: "toolbarOnSelect",
                onClick: (event, data) => {
                    onRetrySelected(data);
                },
            });
        }

        if (showClone) {
            actions.push({
                icon: () => <RepeatIcon fontSize="inherit" />,

                tooltip: "Clone",
                //isFreeAction: false,
                position: "row",
                onClick: (event, data) => {
                    onClone(data);
                },
            });
        }

        if (rowDataOverride) {
            actions.push((rowData) => {
                return rowDataOverride(rowData);
            });
        }

        return actions;
    };

    const getOptions = () => {
        let options = {
            columnsButton: showColumns,
            search: false,
            filtering: showFilters,
            grouping: showGrouping,
            exportButton: showExport,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            padding: "dense",
            debounceInterval: 700,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            rowStyle: (data, index) =>
                index % 2 === 0 ? { background: "#f5f5f5" } : null,
            headerStyle: {
                //     // backgroundColor: "green",
                //     // top: "0",
                //     // position: "sticky",
                fontStyle: "italic",
            },
            // maxBodyHeight: "600px",
            selection: selectableRows,
        };
        return options;
    };

    return (
        <Paper
            variant="elevation"
            elevation={10}
            sx={{
                p: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <MaterialTable
                style={{ fontSize: 14 }}
                icons={tableIcons}
                tableRef={tableRef}
                title={title}
                // data={data}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        let qryForUrl = getBaseUrlExt
                            ? getBaseUrlExt()
                            : baseUrlExt;
                        qryForUrl += "?";

                        let pgSearchPrefix =
                            pagedSearchString && pagedSearchString !== ""
                                ? pagedSearchString + "."
                                : "";
                        if (query.search) {
                            // qryForUrl += `q=${query.search}`;
                        }

                        if (query.orderBy) {
                            qryForUrl += `&${pgSearchPrefix}orderBy=${
                                query.orderDirection === "asc" ? "%2B" : "-"
                            }${query.orderBy.field}`;
                        }

                        if (query.filters.length) {
                            let filters = {};
                            const qryFilters = query.filters.map((filter) => {
                                if (saveFilters) {
                                    filters[filter.column.field] = filter.value;
                                }
                                if (filter.value.includes("%"))
                                    filter.value = filter.value.replace(
                                        "%",
                                        "\\%"
                                    );

                                return `&${pgSearchPrefix}${
                                    filter.column.field
                                }${filter.operator}${encodeURIComponent(
                                    filter.value
                                )}`;
                            });

                            if (saveFilters) {
                                saveFilters(filters);
                            }

                            qryForUrl += qryFilters.join("");
                        }

                        let addFilters = [];
                        if (additionalFilters) {
                            addFilters = additionalFilters.map((addFilter) => {
                                return `&${addFilter}`;
                            });
                        }
                        qryForUrl += addFilters.join("");

                        qryForUrl += `&${pgSearchPrefix}page=${query.page + 1}`;

                        qryForUrl += `&${pgSearchPrefix}pageSize=${query.pageSize}`;

                        createApiEndPoint(fetchEndPoint)
                            .getRaw(qryForUrl)
                            .then((res) => {
                                const dataToDisplay = res.data.items;

                                if (dataToDisplay.length === 0) {
                                    resolve({
                                        data: dataToDisplay,
                                        page:
                                            query.page > 0 ? query.page - 1 : 0,
                                        totalCount: res.data.total,
                                    });
                                    // tableRef.current.dataManager.currentPage = 0;
                                    query.page !== 0 &&
                                        tableRef.current.onQueryChange();
                                } else {
                                    resolve({
                                        data: dataToDisplay,
                                        page: query.page,
                                        totalCount: res.data.total,
                                    });
                                }
                            })
                            .catch((err) => console.log(err));
                    })
                }
                columns={cols}
                actions={getActions()}
                options={getOptions()}
                onRowDoubleClick={onRowDoubleClick}
                // components={{
                //     FilterRow: (rowProps) => {
                //         const { columns, onFilterChanged } = rowProps;
                //         return (
                //             <>
                //                 <tr>
                //                     {columns.map((col) => {
                //                         if (col.field) {
                //                             return (
                //                                 <td>
                //                                     <Box
                //                                         sx={{
                //                                             display: "flex",
                //                                             alignItems:
                //                                                 "flex-end",
                //                                             ml: 1,
                //                                             mb: 2,
                //                                         }}
                //                                     >
                //                                         <FilterList
                //                                             sx={{
                //                                                 color: "action.active",
                //                                                 mr: 1,
                //                                             }}
                //                                         />
                //                                         <TextField
                //                                             variant="standard"
                //                                             placeholder={
                //                                                 col.placeholder
                //                                             }
                //                                             id={col.field}
                //                                             onChange={(e) => {
                //                                                 onFilterChanged(
                //                                                     col
                //                                                         .tableData
                //                                                         .id,
                //                                                     e.target
                //                                                         .value
                //                                                 );
                //                                             }}
                //                                         />
                //                                     </Box>
                //                                 </td>
                //                             );
                //                         }
                //                     })}
                //                 </tr>
                //             </>
                //         );
                //     },
                // }}
            ></MaterialTable>
        </Paper>
    );
}

export default GddTable;
