import { Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import AircraftIcon from "../../icons/aircraft-info/AircraftIcon";
import useAxios from "../../api/useAxios";

function AircraftDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        bulkEdit,
        fetchDataFrame,
        dataFrames,
        reports,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <AircraftIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={
                editMode
                    ? bulkEdit
                        ? "Edit Selected Aircrafts"
                        : "Edit Aircraft"
                    : "Create New Aircraft"
            }
            titleIcon={iconType}
        />
    );

    const [fleets, setFleets] = useState([]);
    const [recorder1, setRecorder1] = useState([]);
    const [recorder2, setRecorder2] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode, bulkEdit);
        }
    };

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.FLEET)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
            .then((res) => {
                const fleets = res.data.items;
                let fleetOptions = fleets
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));

                setFleets(fleetOptions);
            })
            .catch((err) => console.log(err));

      /*  createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
            .then((res) => {
                const recordersconfigs = res.data.items;
                let recordersOptions = recordersconfigs
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));

                setRecorder1(recordersOptions);
                setRecorder2(recordersOptions);
            })
            .catch((err) => console.log(err));*/
    }, []);
   
   
    useEffect(()=> fetchRecorderConfiguration(values.fleetId),[values])



    const fetchRecorderConfiguration = (fleetId) =>{
        createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
        .fetchAll(`getKeyValuePairByFleet?fleetId=${fleetId}`)
        .then(res=>{
            const recordersconfigs = res.data;
            let recordersOptions = recordersconfigs
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((item) => ({
                id: item.id,
                title: item.name,
            }));

        setRecorder1(recordersOptions);
        setRecorder2(recordersOptions);
        })
        .catch(err=>console.log(err));
    }

    return (
        <GddDialog
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                {!bulkEdit && (
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="registration"
                            variant="outlined"
                            label="Registration"
                            required={true}
                            value={values.registration}
                            onChange={handleInputChange}
                            error={errors.registration}
                        />
                    </Grid>
                )}

                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="fleetId"
                        label="Fleet"
                        required={true}
                        options={fleets}
                        value={values.fleetId}
                        onChange={(e) => {
                            fetchDataFrame(e.target.value);
                            fetchRecorderConfiguration(e.target.value);
                            handleInputChange(e);
                        }}
                        error={errors.fleetId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="dataFrameId"
                        label="Data Frame"
                        required={false}
                        options={dataFrames}
                        value={values.dataFrameId}
                        onChange={handleInputChange}
                        error={errors.dataFrameId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="reportMapId"
                        label="Report"
                        required={false}
                        options={reports}
                        value={values.reportMapId}
                        onChange={handleInputChange}
                        error={errors.reportMapId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="recorderConfigurationId1"
                        label="Recorder 1"
                        required={true}
                        options={recorder1}
                        value={values.recorderConfigurationId1}
                        onChange={handleInputChange}
                        error={errors.recorderConfigurationId1}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="recorderConfigurationId2"
                        label="Recorder 2"
                        required={false}
                        options={recorder2}
                        value={values.recorderConfigurationId2}
                        onChange={handleInputChange}
                        error={errors.recorderConfigurationId2}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddCheckbox
                        fullWidth={true}
                        name="isEnabled"
                        label="Enabled"
                        value={values.isEnabled}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
        </GddDialog>
    );
}

export default AircraftDetails;
