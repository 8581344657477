import { ConfirmProvider } from "material-ui-confirm";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import NewDashboard from "./components/layout/NewDashboard";
import { AuthContextProvider } from "./auth/AuthContext";

function App() {
    return (
        <AuthContextProvider>
            <ConfirmProvider
                defaultOptions={{
                    confirmationButtonProps: {
                        startIcon: <DoneIcon />,
                        varient: "contained",
                        color: "primary",
                    },
                    cancellationButtonProps: {
                        startIcon: <CloseIcon />,
                        varient: "contained",
                        color: "secondary",
                    },
                }}
            >
                {/* <Router>
                <Switch>
                    <Route exact path="/" component={LoginPage} />
                    <ProtectedRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                    />
                </Switch>
            </Router> */}

                <NewDashboard />
                {/* <Dashboard />; */}
            </ConfirmProvider>
        </AuthContextProvider>
    );
}

export default App;
