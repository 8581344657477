import {
    Box,
    Grid,
    Paper,
    Step,
    StepButton,
    Stepper,
    Typography,
} from "@mui/material";
import React from "react";
import AirlinesIcon from "../../icons/admin/AirlinesIcon";
import AircraftIcon from "../../icons/aircraft-info/AircraftIcon";
import FleetIcon from "../../icons/aircraft-info/FleetIcon";
import GddHomeIcon from "../../icons/Home/GddHomeIcon";
import DataFramesIcon from "../../icons/param-mapping/DataFramesIcon";
import EndPointIcon from "../../icons/param-mapping/EndPointIcon";
import FlightDataIcon from "../../icons/param-mapping/FlightDataIcon";
import PackageIcon from "../../icons/param-mapping/PackageIcon";
import ReportIcon from "../../icons/param-mapping/ReportIcon";
import AircraftsPage from "../aircrafts/AircraftsPage";
import DataFramesPage from "../datafranes/DataFramesPage";
import EndPointsPage from "../endpoints/EndPointsPage";
import FleetsPage from "../fleets/FleetsPage";
import FlightDataPage from "../flightdata/FlightDataPage";
import PackagesPage from "../packages/PackagesPage";
import ReportsPage from "../reports/ReportsPage";

function MgrHomePage() {
    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [
        {
            label: "1. Data Frames",
            icon: <DataFramesIcon />,
        },
        {
            label: "2. Aircrafts",
            icon: <AircraftIcon />,
        },
        {
            label: "3. Fleet",
            icon: <FleetIcon />,
        },
        {
            label: "4. Flight Data",
            icon: <FlightDataIcon />,
        },
        {
            label: "5. Reports",
            icon: <ReportIcon />,
        },
        {
            label: "6. Endpoints",
            icon: <EndPointIcon />,
        },
        {
            label: "7. Packages",
            icon: <PackageIcon />,
        },
    ];

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                <Paper variant="outlined" elevation={10}>
                    <Grid display="flex">
                        <Grid
                            item
                            style={{
                                marginLeft: 25,
                                marginTop: 25,
                            }}
                        >
                            <GddHomeIcon />
                        </Grid>
                        <Grid
                            item
                            display="flex 1"
                            style={{
                                paddingTop: 4,
                                marginLeft: 20,
                                marginTop: 15,
                            }}
                        >
                            <Typography variant="h6" component="span">
                                Home
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Paper variant="outlined" elevation={10}>
                <Stepper nonLinear sx={{ m: 5 }} activeStep={activeStep}>
                    {steps.map((item, index) => (
                        <Step key={item.label}>
                            <StepButton
                                icon={item.icon}
                                color="inherit"
                                onClick={handleStep(index)}
                            >
                                {item.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && <DataFramesPage />}
                {activeStep === 1 && <AircraftsPage />}
                {activeStep === 2 && <FleetsPage />}
                {activeStep === 3 && <FlightDataPage />}
                {activeStep === 4 && <ReportsPage />}
                {activeStep === 5 && <EndPointsPage />}
                {activeStep === 6 && <PackagesPage />}
            </Paper>
        </>
    );
}

export default MgrHomePage;
