import React, { useState, useEffect, useRef, useContext } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import EndPointIcon from "../../icons/param-mapping/EndPointIcon";
import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";
import EndPointDetails from "./EndPointDetails";
import { AuthContext } from "../../auth/AuthContext";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    transferTypeId: "",
    transferTypeName: "",
    isEnabled: false,
    userName: "",
    password: "",
    ipAddress: "",
    port: "",
    folderPath: "",
    bucket: "",
    region: "",
    accessKey: "",
    secretKey: "",
    encryptionType: "",
    AuthenticateWithSshKey: false,
   
};

function EndPointsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role === "Admin";

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("transferTypeId" in fieldValues)
            temp.transferTypeId =
                fieldValues.transferTypeId !== ""
                    ? ""
                    : "Transfer Type is required";

        if (fpsShow || sftpShow) {
            if ("userName" in fieldValues)
                temp.userName =
                    fieldValues.userName !== "" ? "" : "User Name is required";

            if ("password" in fieldValues)
                temp.password =
                    fieldValues.password !== "" ? "" : "Password is required";

            if ("ipAddress" in fieldValues)
                temp.ipAddress =
                    fieldValues.ipAddress !== ""
                        ? ""
                        : "IP Address is required";

            if ("port" in fieldValues)
                temp.port = fieldValues.port !== "" ? "" : "Port is required";

            if ("folderPath" in fieldValues)
                temp.folderPath =
                    fieldValues.folderPath !== ""
                        ? ""
                        : "Folder Path is required";
        }
        if (awsShow) {
            if ("bucket" in fieldValues)
                temp.bucket =
                    fieldValues.bucket !== "" ? "" : "Bucket name is required";

            if ("region" in fieldValues)
                temp.region =
                    fieldValues.region !== "" ? "" : "Region is required";

            if ("accessKey" in fieldValues)
                temp.accessKey =
                    fieldValues.accessKey !== ""
                        ? ""
                        : "Access Key is required";

            if ("secretKey" in fieldValues)
                temp.secretKey =
                    fieldValues.secretKey !== ""
                        ? ""
                        : "Secret Key is required";

            if ("encryptionType" in fieldValues)
                temp.encryptionType =
                    fieldValues.encryptionType !== ""
                        ? ""
                        : "Encryption Type is required";
        }

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <EndPointIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Endpoints" titleIcon={iconType} />;
    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);
    const [selectedTransferTypeId, setSelectedTransferTypeId] = useState();
    const [fpsShow, setFpsShow] = useState(false);
    const [sftpShow, setSftpShow] = useState(false);
    const [awsShow, setAwsShow] = useState(false);

    const resetValues = () => {
        values.userName = "";
        values.password = "";
        values.ipAddress = "";
        values.port = "";
        values.folderPath = "";
        values.clientId = "";
        values.clientSecret = "";
        values.accessTokensUrl = "";
        values.refreshTokensUrl = "";
        values.bucket = "";
        values.region = "";
        values.accessKey = "";
        values.secretKey = "";
        values.encryptionType = "";
        values.AuthenticateWithSshKey = false;

    };

    const handleShow = (paratransferTypeId) => {
        switch (paratransferTypeId) {
            case 1:
                setFpsShow(true);
                setSftpShow(false);
                setAwsShow(false);
                break;
            case 2:
                setFpsShow(false);
                setSftpShow(true);
                setAwsShow(false);
                break;
            case 3:
                setFpsShow(false);
                setSftpShow(false);
                setAwsShow(true);
                break;
            default:
                setFpsShow(false);
                setSftpShow(false);
                setAwsShow(false);
                break;
        }
    };

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Transfer Type",
            field: "transferType",
            filterPlaceholder: "Filter by Transfer Type",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "Enabled",
            field: "isEnabled",
            render: (rowData) =>
                rowData.isEnabled.toString().charAt(0).toUpperCase() +
                rowData.isEnabled.toString().slice(1),
            filterPlaceholder: "Filter by Enabled",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (ep, isEditMode) => {
        if (!isEditMode) {
            createEndPoint(ep);
        } else {
            updateEndPoint(ep);
        }
    };

    const transferTypeEndPoints = (paratransferTypeId) => {
        switch (paratransferTypeId) {
            case 1:
                return ENDPOINTS.FPSCONFIG;
            case 2:
                return ENDPOINTS.SFTPCONFIG;
            case 3:
                return ENDPOINTS.AWSCONFIG;
            default:
                return "";
        }
    };

    const createEndPoint = (ep) => {
        let apiEP = transferTypeEndPoints(ep.transferTypeId);

        const epForAPI = {
            name: ep.name,
            description: ep.description,
            isEnabled: ep.isEnabled,
           
            configuration: {
                ip: ep.ipAddress,
                port: ep.port,
                userName: ep.userName,
                password: ep.password,
                folderPath: ep.folderPath,
                clientId: ep.clientId,
                clientSecret: ep.clientSecret,
                accessTokensUrl: ep.accessTokensUrl,
                refreshTokensUrl: ep.refreshTokensUrl,
                bucket: ep.bucket,
                region: ep.region,
                accessKey: ep.accessKey,
                secretKey: ep.secretKey,
                encryptionType: ep.encryptionType,
                AuthenticateWithSshKey: ep.AuthenticateWithSshKey,
            },
        };

        createApiEndPoint(apiEP)
            .create(epForAPI)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Endpoint",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Endpoint: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateEndPoint = (ep) => {
        let apiEP = transferTypeEndPoints(ep.transferTypeId);

        const epForAPI = {
            id: ep.id,
            name: ep.name,
            description: ep.description,
            isEnabled: ep.isEnabled,
            
            configuration: {
                ip: ep.ipAddress,
                port: ep.port,
                userName: ep.userName,
                password: ep.password,
                folderPath: ep.folderPath,
                clientId: ep.clientId,
                clientSecret: ep.clientSecret,
                accessTokensUrl: ep.accessTokensUrl,
                refreshTokensUrl: ep.refreshTokensUrl,
                bucket: ep.bucket,
                region: ep.region,
                accessKey: ep.accessKey,
                secretKey: ep.secretKey,
                encryptionType: ep.encryptionType,
                AuthenticateWithSshKey: ep.AuthenticateWithSshKey,
            },
        };

        createApiEndPoint(apiEP)
            .update(epForAPI.id, epForAPI)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [user].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Endpoint",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Endpoint: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteEndPoint = (ep) => {
        let apiEP = transferTypeEndPoints(ep.transferTypeId);

        createApiEndPoint(apiEP)
            .delete(ep.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Endpoint",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Endpoint: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.exceptionMessage;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setSelectedTransferTypeId(0);
        setFpsShow(false);
        setSftpShow(false);
        setAwsShow(false);
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedEP) => {
        resetForm();
        let apiEP = transferTypeEndPoints(selectedEP.transferTypeId);

        createApiEndPoint(apiEP)
            .fetchById(selectedEP.id)
            .then((res) => {
                const ep = res.data;
                
                setValues({
                    ...values,
                    id: selectedEP.id,
                    name: ep.name,
                    description: ep.description,
                    transferTypeId: ep.transferTypeId,
                    isEnabled: ep.isEnabled,
                    userName: ep.configuration.username,
                    password: ep.configuration.password,
                    ipAddress: ep.configuration.ip,
                    port: ep.configuration.port,
                    folderPath: ep.configuration.folderPath,
                    clientId: ep.configuration.clientId,
                    clientSecret: ep.configuration.clientSecret,
                    accessTokensUrl: ep.configuration.accessTokensUrl,
                    refreshTokensUrl: ep.configuration.refreshTokensUrl,
                    bucket: ep.configuration.bucket,
                    region: ep.configuration.region,
                    accessKey: ep.configuration.accessKey,
                    secretKey: ep.configuration.secretKey,
                    encryptionType: ep.configuration.encryptionType,
                    AuthenticateWithSshKey:ep.configuration.authenticateWithSshKey
                      
                });
                setSelectedTransferTypeId(ep.transferTypeId);
                handleShow(ep.transferTypeId);
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Endpoint: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (ep) => {
        confirm({
            description: `Are you sure you want to delete the Endpoint '${ep.name}?'`,
        })
            .then(() => deleteEndPoint(ep))
            .catch(() => {});
    };

    return (
        <div>
            <EndPointDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                // values={values}
                // setValues={setValues}
                // setErrors={setErrors}
                // errors={errors}
                // handleInputChange={handleInputChange}
                // resetForm={resetForm}
                // validate={validate}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                setSelectedTransferTypeId={setSelectedTransferTypeId}
                setFpsShow={setFpsShow}
                fpsShow={fpsShow}
                setSftpShow={setSftpShow}
                sftpShow={sftpShow}
                setAwsShow={setAwsShow}
                awsShow={awsShow}
                handleShow={handleShow}
                resetValues={resetValues}
            />

            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.ENDPOINT_CONFIGURATION}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                showAdd={isAdmin}
                showEdit={isAdmin}
                showDelete={isAdmin}
                options={{ exportFileName: "Endpoints" }}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default EndPointsPage;
