import React, { useState, useEffect, useRef, useContext } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import IOStatIcon from "../../icons/stats/IOStatIcon";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";
import { useConfirm } from "material-ui-confirm";
import { AuthContext } from "../../auth/AuthContext";
import { Stack, TextField } from "@mui/material";

import { format, compareAsc } from "date-fns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function IOStatsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role === "Admin";

    const confirm = useConfirm();

    const iconType = <IOStatIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="IO Stats" titleIcon={iconType} />;

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Date",
            field: "date",

            // filterComponent: ({ columnDef, onFilterChanged }) => (
            //     <TextField
            //         placeholder="custom"
            //         type="date"
            //         onChange={(e) => {
            //             // Calling the onFilterChanged with the current tableId and the new value
            //             onFilterChanged(columnDef.tableData.id, e.target.value);
            //         }}
            //     />
            // ),
            // type: "date",
            // filterPlaceholder: "Filter by Date",
            // filterComponent: ({ columnDef, onFilterChanged }) => (
            //     <LocalizationProvider dateAdapter={AdapterDateFns}>
            //         <DateRangePicker
            //             startText="Check-in"
            //             endText="Check-out"
            //             value={value}
            //             onChange={(newValue) => {
            //                 setValue(newValue);
            //             }}
            //             renderInput={(startProps, endProps) => (
            //                 <React.Fragment>
            //                     <TextField {...startProps} />
            //                     <Box sx={{ mx: 2 }}> to </Box>
            //                     <TextField {...endProps} />
            //                 </React.Fragment>
            //             )}
            //         />
            //     </LocalizationProvider>
            // ),
            filterComponent: ({ columnDef, onFilterChanged }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="From"
                                inputFormat="MM/dd/yyyy"
                                value={startDate}
                                onChange={(newValue) => {
                                    let res = compareAsc(newValue, endDate);
                                    setStartDate(newValue);
                                    if (endDate === null || res === 1)
                                        setEndDate(newValue);
                                    onFilterChanged(
                                        columnDef.tableData.id,
                                        newValue
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                            <DesktopDatePicker
                                label="To"
                                inputFormat="MM/dd/yyyy"
                                value={endDate}
                                onChange={(newValue) => {
                                    let res = compareAsc(newValue, endDate);

                                    setEndDate(newValue);
                                    if (startDate === null || res === -1)
                                        setStartDate(newValue);
                                    onFilterChanged(
                                        columnDef.tableData.id,
                                        newValue
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </Stack>
                    </LocalizationProvider>
                );
            },
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
        },
        // {
        //     title: "Fleet",
        //     field: "fleet",
        //     filterPlaceholder: "Filter by Fleet",
        // },
        {
            title: "Aircraft",
            field: "aircraft",
            filterPlaceholder: "Filter by Aircraft",
        },
        {
            title: "Endpoint",
            field: "endpointName",
            filterPlaceholder: "Filter by Endpoint",
        },
        // {
        //     title: "Bytes",
        //     field: "bytes",
        //     filterPlaceholder: "Filter by Bytes",
        //     type: "numeric",
        // },
        // {
        //     title: "DeID Map",
        //     field: "deIdMap",
        //     filterPlaceholder: "Filter by DeIdMap",
        // },
        {
            title: "Process Type",
            field: "processType",
            filterPlaceholder: "Filter by ProcessType",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const deleteStat = (selectedStat) => {
        createApiEndPoint(ENDPOINTS.IOSTATS)
            .delete(selectedStat.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Stat",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Stat: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteSelectedStats = (selectedStats) => {
        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.IOSTATS +
            "/deleteMultipleIoStats/";
        axiosApiInstance
            .post(url, selectedStats)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted selected stats",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Failure: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (statData) => {
        confirm({
            description: `Are you sure you want to delete the Stat?`,
        })
            .then(() => deleteStat(statData))
            .catch(() => {});
    };

    const deleteSelectedClicked = (statsData) => {
        confirm({
            description: `Are you sure you want to delete the selected stats?`,
        })
            .then(() =>
                deleteSelectedStats(statsData.map((statData) => statData.id))
            )
            .catch(() => {});
    };

    const getAdditionalFilters = () => {
        let startDt = startDate !== null ? format(startDate, "MM/dd/yyyy") : "";
        let endDt = endDate !== null ? format(endDate, "MM/dd/yyyy") : "";
        return [
            `pagedSearch.dateFrom=${startDt}`,
            `pagedSearch.dateTo=${endDt}`,
        ];
    };

    return (
        <div>
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.IOSTATS}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                additionalFilters={getAdditionalFilters()}
                // additionalFilters={[
                //     {
                //         fieldName: "startDate",
                //         value: startDate
                //             ? format(startDate, "mm/dd/yyyy")
                //             : startDate,
                //     },
                //     {
                //         fieldName: "endDate",
                //         value: endDate
                //             ? format(endDate, "mm/dd/yyyy")
                //             : endDate,
                //     },
                // ]}
                options={{ exportFileName: "IOStats" }}
                showAdd={false}
                showEdit={false}
                showDelete={isAdmin}
                showDeleteSelected={isAdmin}
                selectableRows={isAdmin}
                onDelete={deleteClicked}
                onDeleteSelected={deleteSelectedClicked}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default IOStatsPage;
