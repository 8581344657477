import React, { useEffect, useState, useRef, useContext } from "react";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddTable from "../../../components/gdd-table/GddTable";
import TableTitle from "../../../components/gdd-table/TableTitle";
import AirlinesIcon from "../../../icons/admin/AirlinesIcon";
import { useGddForm } from "../../../components/gdd-form/UseGddForm";
import AirlineDetails from "./AirlineDetails";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../../api/useAxios";
import { AuthContext } from "../../../auth/AuthContext";

const initialFieldValues = {
    id: 0,
    code: "",
    name: "",
    endPointId: "",
    isEnabled: false,
    providedLogoFileName: "",
    storedLogoFileName: "",
    isEnabledSarToCsvParquet: false,
    isEnabledACMSReportSplitting: false,
    isEnabled717toCSV: false,
    endPointParams: [],
};

function AirlinesPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role === "Admin";

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("code" in fieldValues)
            temp.code = fieldValues.code !== "" ? "" : "Code is required";

        if ("code" in fieldValues)
            temp.code =
                fieldValues.code !== "" && fieldValues.code.length > 3
                    ? "Code cannot exceed 3 chars in length"
                    : "";

        setErrors({ ...temp });
        // Return the following only in case of whole form validation (not single form validation)

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <AirlinesIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Airlines" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);
    const [endPointParamsList, setEndPointParamsList] = useState([]);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Airline Id",
            hidden: true,
        },
        {
            title: "Code",
            field: "code",
            filterPlaceholder: "Filter by Code",
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Enabled",
            field: "isEnabled",
            filterPlaceholder: "Filter by Enabled",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (airline, isEditMode) => {
        if (!isEditMode) {
            createAirline(airline);
        } else {
            updateAirline(airline);
        }
    };

    const createAirline = (airline) => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .create(airline)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Airline",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Airline: ";
                if (error.response && error.response.data) {
                    if (error.response.data.exceptionMessage) {
                        msg = error.response.data.exceptionMessage;
                    } else {
                        msg = error.response.data.message;
                    }
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateAirline = (airline) => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .update(airline.id, airline)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [res.data].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Airline",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Airline: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteAirline = (airline) => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .delete(airline.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Airline",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Airline: ";
                console.log(error)
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
        setEndPointParamsList([]);
    };

    const editClicked = (selectedAirline) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .fetchById(selectedAirline.id)
            .then((res) => {
                const airline = res.data;
                let selectedEndPoints = [];
                if (airline.endpoints) {
                    selectedEndPoints = airline.endpoints.map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        description: item.name,
                    }));
                    setEndPointParamsList(selectedEndPoints);
                } else {
                    setEndPointParamsList([]);
                }
                setValues({
                    ...values,
                    id: selectedAirline.id,
                    code: airline.code,
                    name: airline.name,
                    //endPointId : airline.endPointId,
                    isEnabled: airline.isEnabled,
                    // providedLogoFileName: airline.providedLogoFileName,
                    storedLogoFileName: airline.storedLogoFileName,
                    isEnabledRawDataExport: airline.isEnabledRawDataExport,
                    isEnabledAcmsReportExport:
                        airline.isEnabledAcmsReportExport,
                    isEnabledEngineeringUnitExport:
                        airline.isEnabledEngineeringUnitExport,
                    endPointParams: [...selectedEndPoints],
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Airline: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (airline) => {
        confirm({
            description: `Are you sure you want to delete the airline '${airline.code}?'`,
        })
            .then(() => deleteAirline(airline))
            .catch(() => {});
    };

    return (
        <div>
            <AirlineDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                // values={values}
                // setValues={setValues}
                // setErrors={setErrors}
                // errors={errors}
                // handleInputChange={handleInputChange}
                // resetForm={resetForm}
                // validate={validate}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                endPointParamsList={endPointParamsList}
                setEndPointParamsList={setEndPointParamsList}
            />

            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.AIRLINE}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                showAdd={isAdmin}
                showEdit={isAdmin}
                showDelete={isAdmin}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default AirlinesPage;
