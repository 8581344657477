import React, { useState, useEffect, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import PackageIcon from "../../icons/param-mapping/PackageIcon";
import PackageDetails from "./PackageDetails";

import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    airlineId: "",
    aircraftSelection: [],
    flightDataSelection: null,
    reportDataSelection: null,
    endPointSelection: [],
    zipOptionId: "",
};

function PackagesPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values, dependentVals = null) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        if ("aircraftSelection" in fieldValues)
            temp.aircraftSelection =
                fieldValues.aircraftSelection.length > 0
                    ? ""
                    : "Aircraft is required";

        if ("flightDataSelection" in fieldValues) {
            temp.flightDataSelection =
                fieldValues.flightDataSelection ||
                fieldValues.reportDataSelection ||
                dependentVals?.reportDataSelection
                    ? ""
                    : "Either Flight Data or Reports Data is required";
            temp.reportDataSelection = temp.flightDataSelection;
        }

        if ("reportDataSelection" in fieldValues) {
            temp.reportDataSelection =
                fieldValues.reportDataSelection ||
                fieldValues.flightDataSelection ||
                dependentVals?.flightDataSelection
                    ? ""
                    : "Either Flight Data or Reports Data is required";

            temp.flightDataSelection = temp.reportDataSelection;
        }

        if ("endPointSelection" in fieldValues)
            temp.endPointSelection =
                fieldValues.endPointSelection.length > 0
                    ? ""
                    : "Endpoint Data is required";

        if ("zipOptionId" in fieldValues)
            temp.zipOptionId =
                fieldValues.zipOptionId !== "" ? "" : "Zip Option is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <PackageIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Packages" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);
    const [selectedDataFrameId, setSelectedDataFrameId] = useState();
    const [selectedAirlineId, setSelectedAirlineId] = useState();
    const [xferTableRefresher, setXferTableRefresher] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "DeID Map",
            field: "deIdMap",
            filterPlaceholder: "Filter by DeID Map",
        },
        {
            title: "Report Configuration",
            field: "reportConfiguration",
            filterPlaceholder: "Filter by Report Config",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (packageData, isEditMode) => {
        let dataToPost = {
            id: packageData.id,
            name: packageData.name,
            description: packageData.description,
            airlineId: packageData.airlineId,
            aircrafts: packageData.aircraftSelection.map((item) => ({
                id: item.id,
                name: item.label,
            })),
            deId: packageData.flightDataSelection
                ? {
                      id: packageData.flightDataSelection.id,
                      name: packageData.flightDataSelection.label,
                  }
                : null,
            reportConfig: packageData.reportDataSelection
                ? {
                      id: packageData.reportDataSelection.id,
                      name: packageData.reportDataSelection.label,
                  }
                : null,
            endPoints: packageData.endPointSelection.map((item) => ({
                id: item.id,
                name: item.label,
            })),
            zipOptionsId: packageData.zipOptionId,
        };

        console.log(dataToPost);

        if (!isEditMode) {
            createPackage(dataToPost);
        } else {
            updatePackage(dataToPost);
        }
    };

    const createPackage = (packageData) => {
        createApiEndPoint(ENDPOINTS.PACKAGES)
            .create(packageData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Package",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Package: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updatePackage = (packageData) => {
        createApiEndPoint(ENDPOINTS.PACKAGES)
            .update(packageData.id, packageData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Package",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Package: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deletePackage = (packageData) => {
        createApiEndPoint(ENDPOINTS.PACKAGES)
            .delete(packageData.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Package",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Package: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedPackage) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.PACKAGES)
            .fetchById(selectedPackage.id)
            .then((res) => {
                const packageData = res.data;
                let aircraftSelection = null;
                if (packageData && packageData.aircrafts) {
                    aircraftSelection = packageData.aircrafts.map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        description: item.name,
                    }));
                }

                let flightDataSelection =
                    packageData && packageData.deId
                        ? {
                              id: packageData.deId.id,
                              label: packageData.deId.name,
                              value: packageData.deId.name,
                              description: packageData.deId.name,
                          }
                        : null;

                let reportDataSelection =
                    packageData && packageData.reportConfig
                        ? {
                              id: packageData.reportConfig.id,
                              label: packageData.reportConfig.name,
                              value: packageData.reportConfig.name,
                              description: packageData.reportConfig.name,
                          }
                        : null;

                let endPointSelection = null;

                if (packageData && packageData.endPoints) {
                    endPointSelection = packageData.endPoints.map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.name,
                        description: item.name,
                    }));
                }

                setValues({
                    ...values,
                    id: selectedPackage.id,
                    name: packageData.name,
                    description: packageData.description,
                    airlineId: packageData.airlineId,
                });

                setValues({
                    ...values,
                    id: selectedPackage.id,
                    name: packageData.name,
                    description: packageData.description,
                    airlineId: packageData.airlineId,
                    aircraftSelection: aircraftSelection,
                    flightDataSelection: flightDataSelection,
                    reportDataSelection: reportDataSelection,
                    endPointSelection: endPointSelection,
                    zipOptionId: packageData.zipOptionsId,
                });
                setTimeout(() => {
                    setEditMode(true);
                    setopenPopup(true);
                }, 500);
            })
            .catch((error) => {
                let msg = "Failed to fetch User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (packageData) => {
        confirm({
            description: `Are you sure you want to delete the Package '${packageData.name}'?`,
        })
            .then(() => deletePackage(packageData))
            .catch(() => {});
    };

    return (
        <div>
            <PackageDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                isAdmin={true}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                selectedDataFrameId={selectedDataFrameId}
                setSelectedDataFrameId={setSelectedDataFrameId}
                selectedAirlineId={selectedAirlineId}
                setSelectedAirlineId={setSelectedAirlineId}
                xferTableRefresher={xferTableRefresher}
                setXferTableRefresher={setXferTableRefresher}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.PACKAGES}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                options={{ exportFileName: "Packages" }}
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default PackagesPage;
