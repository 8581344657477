import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import ParameterMapIcon from "../../icons/param-mapping/ParameterMapIcon";
import FlightDataIcon from "../../icons/param-mapping/FlightDataIcon";
import ReportIcon from "../../icons/param-mapping/ReportIcon";
import EndPointIcon from "../../icons/param-mapping/EndPointIcon";
import PackageIcon from "../../icons/param-mapping/PackageIcon";
import styled from "styled-components/";
import { Tooltip } from "@mui/material";

const ColorLink = styled(Link)``;

function ParameterMappingNav({ iconColor, routeTo, showToolTip }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Tooltip
                    title={showToolTip ? "Parameter Mapping" : ""}
                    arrow={true}
                >
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <ParameterMapIcon iconColor={iconColor} />
                        </ListItemIcon>
                        <ListItemText primary="Parameter Mapping" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/flightdata"
                        >
                            <Tooltip
                                title={showToolTip ? "Flight Data" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FlightDataIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Flight Data" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>
                        <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/reports"
                        >
                            <Tooltip
                                title={showToolTip ? "Reports" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ReportIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Reports" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>

                        <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/packages"
                        >
                            <Tooltip
                                title={showToolTip ? "Packages" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PackageIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Packages" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default ParameterMappingNav;
