import React, { useState, useEffect, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import FlightDataIcon from "../../icons/param-mapping/FlightDataIcon";
import FlightDataDetails from "./FlightDataDetails";

import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    redactionTypeId: "",
    redactionType: "",
    dataFrameId: "",
    dataFrameName: "",
    airlineId: "",
    textualOutput: false,
    redactedParams: [],
};

function FlightDataPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("redactionTypeId" in fieldValues)
            temp.redactionTypeId =
                fieldValues.redactionTypeId !== ""
                    ? ""
                    : "Redaction Type is required";

        if ("dataFrameId" in fieldValues)
            temp.dataFrameId =
                fieldValues.dataFrameId !== "" ? "" : "Data Frame is required";

        if ("redactedParams" in fieldValues)
            temp.redactedParams =
                fieldValues.redactedParams.length > 0
                    ? ""
                    : "Parameters selection is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <FlightDataIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Flight Data" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);
    const [textOutShow, setTextOutShow] = useState(false);

    const handleShow = (redactionTypeId) => {
        if (redactionTypeId === 1) {
            setTextOutShow(false);
            values.textualOutput = false;
        } else setTextOutShow(true);
    };

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "Data Frame",
            field: "dataFrame",
            filterPlaceholder: "Filter by Data Frame",
        },
        {
            title: "Redaction Type",
            field: "redactionType",
            filterPlaceholder: "Filter by Redaction Type",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (flightData, isEditMode) => {
        let dataToPost = {
            mappingData: {
                id: flightData.id,
                name: flightData.name,
                description: flightData.description,

                parameterList: flightData.redactedParams.map((item) => ({
                    id: item.label,
                    mnemonic: item.label,
                    description: item.description,
                })),
                dataFrameId: flightData.dataFrameId,
                airlineId: flightData.airlineId,
                textualOutput: flightData.textualOutput,
                redactionTypeId: flightData.redactionTypeId,
            },
        };

        if (!isEditMode) {
            createFlightData(dataToPost);
        } else {
            updateFlightData(dataToPost);
        }
    };

    const createFlightData = (flightData) => {
        createApiEndPoint(ENDPOINTS.FLIGHTDATA)
            .create(flightData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Flight Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Flight Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateFlightData = (flightData) => {
        createApiEndPoint(ENDPOINTS.FLIGHTDATA)
            .update(flightData.id, flightData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Flight Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Flight Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteFlightData = (flightData) => {
        createApiEndPoint(ENDPOINTS.FLIGHTDATA)
            .delete(flightData.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Flight Data",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Flight Data: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setTextOutShow(false);
        setopenPopup(true);
    };

    const editClicked = (selectedFD) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.FLIGHTDATA)
            .fetchById(selectedFD.id)
            .then((res) => {
                const flightData = res.data.mappingData;
                let selectedParams = [];
                if (flightData.parameterList) {
                    selectedParams = flightData.parameterList.map((item) => ({
                        id: item.mnemonic,
                        label: item.mnemonic,
                        value: item.mnemonic,
                        description: item.description,
                    }));
                }

                setValues({
                    ...values,

                    id: selectedFD.id,
                    name: flightData.name,
                    description: flightData.description,
                    redactionTypeId: flightData.redactionTypeId,
                    redactionType: flightData.redactionType,
                    dataFrameId: flightData.dataFrameId,
                    dataFrameName: flightData.dataFrame,
                    airlineId: flightData.airlineId,
                    textualOutput: flightData.textualOutput,
                    redactedParams: selectedParams,
                });
                if (flightData.redactionTypeId !== 1) setTextOutShow(true);
                else setTextOutShow(false);

                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch User: ";
                if (error.response) {
                    msg =
                        msg +
                        "Status Code:  " +
                        error.response.status +
                        " Error: " +
                        error.response.data;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (flightData) => {
        confirm({
            description: `Are you sure you want to delete the Flight Data '${flightData.name}?'`,
        })
            .then(() => deleteFlightData(flightData))
            .catch(() => {});
    };

    return (
        <div>
            <FlightDataDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                isAdmin={true}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
                textOutShow={textOutShow}
                handleShow={handleShow}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.FLIGHTDATA}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPageNoRedaction"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                options={{ exportFileName: "FlightData" }}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default FlightDataPage;
