import React, { useContext, useState } from "react";
import { AuthContext } from "../../auth/AuthContext";
import GddControls from "../../components/gdd-controls/GddControls";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import logoImage from "../../images/logo.png";
import { useGddForm } from "../../components/gdd-form/UseGddForm";

function Footer(props) {
    return (
        <Grid align="center" style={{ marginBottom: "-40px" }} {...props}>
            <img
                src={logoImage}
                alt="TDY logo"
                height="65px"
                style={{ marginTop: "40px", align: "center" }}
            />
            <Typography variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="www.teledynecontrols.com">
                    Teledyne Controls
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Grid>
    );
}

const initialValues = {
    username: "",
    password: "",
};

function LoginPage(props) {
    const { loggedInUser, setLoggedInUser } = useContext(AuthContext);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("username" in fieldValues)
            temp.username = fieldValues.username !== "" ? "" : "Required";

        if ("password" in fieldValues)
            temp.password = fieldValues.password !== "" ? "" : "Required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialValues, validate, true);

    const history = useHistory();

    const login = async (userName, password) => {
        const tokenUrl = window.env.REACT_APP_API_BASE_URL + "/oauth/token";
        const config = {
            headers: {
                Authorization:
                    "BASIC Q2xpZW50MTpFRUY0N0Q5QS1EQkE5LTREMDItQjdCMC0wNEY0Mjc5QTZEMjA=",
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const postData = new URLSearchParams();
        postData.append("username", userName);
        postData.append("password", password);
        postData.append("grant_type", "password");

        try {
            const res = await axios.post(tokenUrl, postData, config);
            let token = { ...res.data };
            let decodedAccess = jwt_decode(token.access_token);
            document.cookie = `refresh_token=${token.refresh_token}`;
            setLoggedInUser({
                ...loggedInUser,
                userName: decodedAccess.unique_name,
                role: decodedAccess.role,
                accessToken: token.access_token,
                expiresIn: token.expires_in,
                tokenType: token.token_type,
            });
            resetForm();

            if (decodedAccess?.role.toLowerCase() === "admin")
                history.push("/airlines");
            else history.push("/aircrafts");

            // window.location.reload();

            // window.location = "/aircrafts";


        } catch (err) {
            console.log(err.response);
            let errMsg = "";
            if (!err?.response) {
                errMsg = "No server response.";
            } else {
                errMsg = err.response?.data?.error;
                errMsg =
                    errMsg === "invalid_grant"
                        ? "Invalid combination of username and password"
                        : errMsg;
            }
            // } else if (err.response?.status === 400) {
            //     errMsg = "Missing username or password";
            // } else if (err.response?.status === 401) {
            //     errMsg = "Unauthorized";
            // } else {
            //     errMsg = "Login failed";
            // }
            setSnackbarState({
                ...snackbarState,
                openSnackbar: true,
                snackbarMsg: errMsg,
                snackbarSeverity: "error",
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            login(values.username, values.password);
        }
    };

    return (
        <>
            <Grid {...props}>
                <Card
                    elevation={20}
                    style={{
                        padding: 20,
                        // height: "75vh",
                        width: "320px",
                        // width: "25vw",
                        margin: "20px auto",
                    }}
                >
                    <CardContent>
                        <Grid align="center">
                            <Avatar style={{ backgroundColor: "#64b1b7" }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <h2>Sign In</h2>
                        </Grid>

                        <GddControls.GddTextField
                            fullWidth={true}
                            autoComplete="off"
                            variant="standard"
                            label="Username"
                            name="username"
                            placeholder="Enter Username"
                            required={true}
                            value={values.username}
                            error={errors.username}
                            onChange={handleInputChange}
                        />
                        <GddControls.GddTextField
                            style={{ marginTop: "5px" }}
                            fullWidth
                            required
                            type="password"
                            variant="standard"
                            label="Password"
                            name="password"
                            placeholder="Enter Password"
                            value={values.password}
                            error={errors.password}
                            onChange={handleInputChange}
                        />
                        <Grid align="center">
                            <FormControlLabel
                                style={{ marginTop: "5px" }}
                                control={
                                    <Checkbox
                                        name="rememberMe"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                        </Grid>
                        <Button
                            style={{ margin: "8px 0" }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                            onClick={(e) => handleSubmit(e)}
                        >
                            Sign In
                        </Button>
                        <Grid align="center">
                            <Typography style={{ marginTop: "5px" }}>
                                <Link
                                    href="/account/forgotPassword"                                  
                                >
                                    Forgot password?
                                </Link>
                            </Typography>
                            {/* <Footer /> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </>
    );
}

export default withRouter(LoginPage);
