import React, { useState, useEffect, useRef, useContext } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import FailureIcon from "../../icons/stats/FailureIcon";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";
import { useConfirm } from "material-ui-confirm";
import { AuthContext } from "../../auth/AuthContext";

import { Stack, TextField } from "@mui/material";

import { format, compareAsc } from "date-fns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function FailuresPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role === "Admin";

    const confirm = useConfirm();

    const iconType = <FailureIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Failures" titleIcon={iconType} />;

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Date",
            field: "date",
            filterComponent: ({ columnDef, onFilterChanged }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="From"
                                inputFormat="MM/dd/yyyy"
                                value={startDate}
                                onChange={(newValue) => {
                                    let res = compareAsc(newValue, endDate);
                                    setStartDate(newValue);
                                    if (endDate === null || res === 1)
                                        setEndDate(newValue);
                                    onFilterChanged(
                                        columnDef.tableData.id,
                                        newValue
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                            <DesktopDatePicker
                                label="To"
                                inputFormat="MM/dd/yyyy"
                                value={endDate}
                                onChange={(newValue) => {
                                    let res = compareAsc(newValue, endDate);

                                    setEndDate(newValue);
                                    if (startDate === null || res === -1)
                                        setStartDate(newValue);
                                    onFilterChanged(
                                        columnDef.tableData.id,
                                        newValue
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </Stack>
                    </LocalizationProvider>
                );
            },
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "File Path",
            field: "filePath",
            filterPlaceholder: "Filter by File Path",
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
        },
        {
            title: "Fleet",
            field: "fleet",
            filterPlaceholder: "Filter by Fleet",
        },
        {
            title: "Aircraft",
            field: "aircraft",
            filterPlaceholder: "Filter by Aircraft",
        },
        {
            title: "Error Message",
            field: "errorMessage",
            filterPlaceholder: "Filter by Error Message",
        },
        {
            title: "Type",
            field: "type",
            filterPlaceholder: "Filter by Type",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const deleteFailure = (selectedFailure) => {
        createApiEndPoint(ENDPOINTS.FAILURES)
            .delete(selectedFailure.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Failure",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Failure: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteSelectedFailures = (selectedFailures) => {
        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.FAILURES +
            "/deleteMultipleFailures/";
        axiosApiInstance
            .post(url, selectedFailures)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted selected failures",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Failure: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const retryFailure = (selectedFailure) => {
        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.FAILURES +
            "/changeRetryStatus";
        axiosApiInstance
            .put(url + `?id=${selectedFailure.id}`)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully set Failure to be retried",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to set the Failure to be retried: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const retrySelectedFailures = (selectedFailures) => {
        let url =
            window.env.REACT_APP_API_BASE_URL +
            "/api/" +
            ENDPOINTS.FAILURES +
            "/changeMultipleRetryStatus/";
        axiosApiInstance
            .put(url, selectedFailures)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg:
                        "Successfully set the selected failures to be retried",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to set the selected failures to be retried: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (failureData) => {
        confirm({
            description: `Are you sure you want to delete the Failure '${failureData.name}'?`,
        })
            .then(() => deleteFailure(failureData))
            .catch(() => {});
    };

    const deleteSelectedClicked = (failuresData) => {
        confirm({
            description: `Are you sure you want to delete the selected failures '${failuresData.map(
                (failureData) => failureData.name
            )}'?`,
        })
            .then(() =>
                deleteSelectedFailures(
                    failuresData.map((failureData) => failureData.id)
                )
            )
            .catch(() => {});
    };

    const retryClicked = (failureData) => {
        confirm({
            description: `Are you sure you want to retry the Failure '${failureData.name}'?`,
        })
            .then(() => retryFailure(failureData))
            .catch(() => {});
    };

    const retrySelectedClicked = (failuresData) => {
        confirm({
            description: `Are you sure you want to retry the selected failure '${failuresData.map(
                (failureData) => failureData.name
            )}'?`,
        })
            .then(() =>
                retrySelectedFailures(
                    failuresData.map((failureData) => failureData.id)
                )
            )
            .catch(() => {});
    };

    const getAdditionalFilters = () => {
        let startDt = startDate !== null ? format(startDate, "MM/dd/yyyy") : "";
        let endDt = endDate !== null ? format(endDate, "MM/dd/yyyy") : "";
        return [
            `pagedSearch.dateFrom=${startDt}`,
            `pagedSearch.dateTo=${endDt}`,
        ];
    };

    return (
        <div>
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.FAILURES}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                additionalFilters={getAdditionalFilters()}
                options={{ exportFileName: "Failures" }}
                showAdd={false}
                showEdit={false}
                showDelete={isAdmin}
                showDeleteSelected={isAdmin}
                showRetry={true}
                showRetrySelected={true}
                selectableRows={true}
                onDelete={deleteClicked}
                onDeleteSelected={deleteSelectedClicked}
                onRetry={retryClicked}
                onRetrySelected={retrySelectedClicked}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default FailuresPage;
